<template>
  <div>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="fas fa-user"></i><span class="ml-3">Clock In</span>
      </h3>
    </div>
    <div>
      <div>
    <v-row>
    <v-col class="d-flex" cols="12" sm="3">
        <v-text-field
          v-model="search"
          label="Search "
          counter
          filled
          rounded
          clearable
          dense
          maxlength="50"
        >  <template #label>
      <i class="fas fa-search"></i>  Search
    </template> </v-text-field>
      </v-col>
      <v-col class="d-flex mt-4" cols="12" sm="6">
        <v-btn > Search </v-btn></v-col
      ></v-row
    >
  </div>  
    </div>
    <div 
      class="container mt-10"
      style="background-color: #ffffff"
    >
      <b-table
        class="table table-bordered"
        show-empty
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0"
        striped
        hover
        select-mode="single"
        selectable
    
      >
      <template #cell(flag)="row">
        <span :class="statusClass(row.item.flag)">
          {{ row.item.flag }}
        </span>
      </template>
        <template #cell(actions)="row">
          <button  class="mr-1">
            <i class="far fa-eye edit_btn"></i>
          </button>
          <button
            v-b-modal.confirmModal
            class="mr-1"
          >
            <i class="fas fa-trash-alt del_btn"></i>
          </button>
        </template>
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination
            size="md"
            v-if="perPage != '-1'"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px"><label for="">Show : </label></span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div>
      </div>
    </div>
    <!-- <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div> -->

   
  </div>
</template>
<script>

export default {

  data() {
    return {
      isEditAble: true,
      isfetching: false,
      isRemoving: false,
      isSending: false,
      roleid: "",
      items: [
      {
        name: "John Doe",
        ClockIn: "Client A",
        ClockIn: "10:00 AM",
        clockOut: "12:00 PM",
        endtime: "12:00 PM",
        date: "2023-05-20",
        service: "Home Cure",
        totalhours: "24",
        flag: "On Site",
      },
      {
        name: "Jane Smith",
        ClockIn: "Client A",
        ClockIn: "10:00 AM",
        clockOut: "12:00 PM",
        endtime: "12:00 PM",
        date: "2023-05-20",
        service: "Home Cure",
        totalhours: "14",
        flag: "25-30km Away",
      },
      {
        name: "Mike Johnson",
        ClockIn: "Client A",
        ClockIn: "10:00 AM",
        clockOut: "12:00 PM",
        endtime: "12:00 PM",
        date: "2023-05-20",
        service: "Home Cure",
        totalhours: "21",
        flag: "25-30km Away",
      },
    ],
      fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "ClockIn",
          label: "Clock In",
        },
        {
          key: "clockOut",
          label: "Clock Out",
        },
        {
          key: "endtime",
          label: "End Time",
        },
        {
          key: "date",
          label: "Date",
        },
        {
          key: "totalhours",
          label: "Total Hours",
        },
        {
          key: "service",
          label: "Service",
        },
        {
          key: "flag",
          label: "Flag",
        },
    
        {
          key: "options",
          label: "Options",
        },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 10,
      search: "",
      roleName: "",
      phoneNumber: "",
      location: "",
      id: "",
      type: "",
      caseworkers: [],
      rules: {
        required: (value) => !!value || "Role Name is Required.",
        counter: (value) => value.length <= 50 || "Max 50 characters",
      },
    };
  },
  methods: {
  statusClass(flag) {
    if (flag === "Approved") {
      return "text-green-500";
    } else if (flag === "Pending") {
      return "text-yellow-500";
    } else {
      return "";
    }
  },
},
  
};
</script>
<style scoped>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}
.table {
  box-shadow: 0 0 5px #ccc;
}
.row {
  margin: 0px !important;
}
.requiredfield {
  color: rgba(252, 9, 9, 0.7);
}
.form-group.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}

.edit_btn{
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #FB8C00 !important;
};
.del_btn{
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #BF360C !important;
}
.text-green-500 {
color: #10b981 !important;
}
.text-yellow-500 {
color: #f59e0b !important;
}
</style>
